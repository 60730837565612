import React from 'react';
import '../styles/Section2.css'; // Ensure you have a corresponding CSS file for styling

function Section2() {
  return (
    <section className="section2-container">
      <h1 className="section2-title">Who We Are</h1>
      <p className="section2-text">
        Remsight is a technology applicator and service provider specializing in surface water management and analytics. We empower water providers and users with the tools and insights they need to achieve efficient and cost-effective water solutions. 
        <br /><br />
        Founded in the Bitterroot Valley (Victor, Montana), Remsight integrates all its technology locally in Montana, and is a service-disabled Veteran-owned business.
      </p>
    </section>
  );
}

export default Section2;
