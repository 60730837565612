import React from 'react';
import '../styles/Section3.css'; // Import the CSS file

// Example icons. Replace these paths with your actual icons
import waterUsersIcon from '../assets/water-users-icon.png';
import waterProvidersIcon from '../assets/water-providers-icon.png';

function Section3() {
  return (
    <section className="section3-container">
      <h2 className="section3-title">Who We Serve</h2>

      <div className="section3-content">
        <div className="section3-item">
          <img src={waterUsersIcon} alt="Water Users" className="section3-icon" />
          <h3 className="section3-subtitle">Water Users</h3>
          <p className="section3-description">
            Landowners, Agricultural Producers, Conservation Organizations, etc.
          </p>
        </div>

        <div className="section3-item">
          <img src={waterProvidersIcon} alt="Water Providers" className="section3-icon" />
          <h3 className="section3-subtitle">Water Providers</h3>
          <p className="section3-description">
            Water Associations, Irrigation Districts, Water Cooperatives, etc.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Section3;
