import React from 'react';
import '../styles/Section1.css'; // Assuming you have a separate CSS file for Section1
import videoFile from '../assets/Websight Landing Low.mp4'; // Update the path to your logo image

function Section1() {
  return (
    <section className="section1-container">
      <video  className="video-player" autoPlay loop muted playsInline>
        <source src={videoFile} type="video/mp4" />
        </video> 
        
    </section>
  );
}

export default Section1;
