import React from 'react';
import '../styles/Section5.css'; // Import the CSS file for Section5
import underConstructionImage from '../assets/underConstruction.png'; // Path to your image

function Section5() {
  return (
    <section className="section5-container">
      <div className="banner">
        {/* You can use text or an image for the banner */}
        <h2 className="section5-title">Coming Soon</h2>
        <p className='section5-subtitle'>We urge you to check back frequently as we build out our site!</p>
        <img
          src={underConstructionImage}
          alt="Under Construction"
          className="section5-image"
        />
      </div>
    </section>
  );
}

export default Section5;
