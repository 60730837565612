import React from 'react';
import SectionOne from './components/Section1.js';
import SectionTwo from './components/Section2.js';
import SectionThree from './components/Section3.js';
import SectionFour from './components/Section4.js';
import SectionFive from './components/Section5';
import ContactForm from './components/ContactForm.js';
import './styles/App.css'; // Styles specific to App component

function App() {
  return (
    <div className="App">
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <ContactForm />
    </div>
  );
}

export default App;
