import React from 'react';
import '../styles/Section4.css'; // Make sure you have the corresponding CSS

function Section4() {
  return (
    <section className="section4-container">
      <h2 className="section4-title">How We Serve</h2>

      <div className="section4-grid">
        {/* Column 1 */}
        <div className="section4-column">
          <h3 className="section4-category">Scan</h3>
          <ul className="section4-list">
            <li>Property Mapping</li>
            <li>Digital Elevation Model</li>
            <li>Optical, Multispectral, IR, etc. (UAS)</li>
          </ul>
        </div>

        {/* Column 2 */}
        <div className="section4-column">
          <h3 className="section4-category">Sense</h3>
          <ul className="section4-list">
            <li>Flow Monitoring</li>
            <li>Soil State</li>
            <li>Crop Health</li>
          </ul>
        </div>

        {/* Column 3 */}
        <div className="section4-column">
          <h3 className="section4-category">See</h3>
          <ul className="section4-list">
            <li>Hydrologic Flow Modeling</li>
            <li>User Dashboard</li>
            <li>Analytics / Historical Data</li>
          </ul>
        </div>

        {/* Column 4 */}
        <div className="section4-column">
          <h3 className="section4-category">Schedule</h3>
          <ul className="section4-list">
            <li>Automated Scheduling</li>
            <li>Communication & Coordination</li>
          </ul>
        </div>

        {/* Column 5 */}
        <div className="section4-column">
          <h3 className="section4-category">Sustain</h3>
          <ul className="section4-list">
            <li>Prescription Irrigation</li>
            <li>Automated Flood Irrigation</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Section4;
