import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import '../styles/ContactForm.css'; // Import the CSS file
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function ContactForm() {
  const form = useRef();
  const [captchaToken, setCaptchaToken] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendEmail = async (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error("Execute recaptcha not yet available");
      return;
    }

    try {
      const token = await executeRecaptcha("contact_form");
      console.log("reCAPTCHA token:", token);  // Log the token to the console

      setCaptchaToken(token);

      emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then((result) => {
        console.log(result.text);
        alert('Message sent successfully!');
        form.current.reset(); // Reset the form after successful submission
      }, (error) => {
        console.log(error.text);
        alert('An error occurred, please try again.');
      });
    } catch (error) {
      console.error("reCAPTCHA failed:", error);
      alert("Failed to verify CAPTCHA. Please try again.");
    }
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="contact-form">
      <h2 className="form-title">Contact Us</h2>

      <label htmlFor="name" className="form-label">Name</label>
      <input type="text" name="user_name" required className="form-input" />

      <label htmlFor="email" className="form-label">Email</label>
      <input type="email" name="user_email" required className="form-input" />

      <label htmlFor="message" className="form-label">Message</label>
      <textarea name="message" required className="form-textarea"></textarea>

      <input type="submit" value="Send" className="form-submit" />
    </form>
  );
}

function ContactPage() {
  return (
    <div>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_ID}>
      <ContactForm />
    </GoogleReCaptchaProvider>
    {/* Inline CSS for grecaptcha-badge */}
    <div
      className="grecaptcha-badge"
      style={{ visibility: 'visible' }}  // Example: Hiding the badge
    ></div>
  </div>
  );
}

export default ContactPage;
